<template>
    <div class="user-container">这里是尾气监测界面</div>
</template>

<script>
export default {

}
</script>

<style>
</style>